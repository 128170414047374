.feature-info {
   border-top: 0 !important;
   position: absolute;
   top: 0;
   right: 0;
   width: 500px;

   .nav {
      display: inline-flex;
      flex-direction: column;
      position: absolute;
      left: -40px;
      top: 31px;

      .nav-link {
         background: #f1f1f1;
         width: 41px;
         color: #333333;
         transition: none !important;

         &:hover {
            border-color: #8aaec3;
         }
      }

      .nav-link.active {
         border-right: 0 !important;
         font-weight: 500;
      }

      .nav-link,
      .nav-link.active {
         border-radius: 0;
         border: 1px solid #8aaec3;
      }
   }

   .box-header::after {
      transform: rotate(-90deg) !important;
   }

   &:not(.box-expanded) {
      width: 100px !important;

      .box-header {
         border-bottom: 0 !important;
         justify-content: center;

         &::after {
            transform: rotate(90deg) !important;
         }      
      }

      .box-content {
         display: none !important;
      }
   }

   .feature {
      margin-bottom: 30px;

      .box-row {
         margin-bottom: 3px !important;

         .value {
            max-height: unset !important;
            overflow-y: unset !important;
         }
      }

      .divider {
         border-bottom: 1px dashed #d5d5d5;
         margin: 10px 0 6px 0;
      }

      &:last-child {
         margin-bottom: 0;
      }
   }

   .feature-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      img,
      .no-legend {
         width: 33px;
         height: 33px;
         margin-right: 10px;
      }

      .name {
         font-size: 13px;
         font-weight: 500;
         margin-right: 5px;
      }

      .zoom {
         background: url("../../../assets/gfx/icon-zoom.svg") no-repeat center center;
         width: 33px;
         height: 33px;
         border: 0;
         padding: 0;
      }
   }

   .feature-content {
      max-height: 400px;
      overflow-y: auto;
   }

   .error-messages {
      margin-top: 10px;

      h5 {
         font-weight: 500;
         margin-bottom: 5px;
         font-size: 13px;
         color: #db3636;
      }

      ol {
         padding: 0 12px;
         margin-bottom: 0;
         max-height: 150px;
         overflow-y: auto;
      }

      span[role=button] {
         color: #db3636;
         text-decoration: underline;
         margin-left: 10px;

         &:hover {
            text-decoration: none;
         }
      }
   }
}
