@import "~bootstrap/scss/bootstrap";
@import "~ol/ol.css";
@import "./styles/_buttons";
@import "./styles/_dialogs";
@import "./styles/_forms";
@import "./styles/_overrides";

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background: #f7f7f7;
   color: #333333;
   font-size: 13px;
}

b,
strong {
   font-weight: 500;
}

.capitalize {
   text-transform: capitalize;
}
