h1 {
   font-size: 22px;
   font-weight: 100;
   margin-bottom: 0;
}

h3 {
   font-size: 16px;
   margin-bottom: 15px;
}

.modal-open {
   padding-right: 0 !important;
}

.form-control,
.btn {
   font-size: 13px !important;
}

.btn-link {
   padding: 0;

   &:focus {
      box-shadow: none !important;
      color: initial !important;
   }
}

.btn-primary {
   background-color: #8aaec3;
   border-color: #8aaec3;
   border-radius: 0;

   &:hover,
   &:focus {
      background-color: #5c7d91;
      border-color: #5c7d91;
      box-shadow: none;
   }

   &:active {
      background-color: #8aaec3;
      border-color: #8aaec3;
   }

   &:active:focus {
      box-shadow: none;
   }
}

.modal-dialog {
   max-width: 1000px;
   transform: none !important;
}

.modal-header {
   background-color: #8aaec3;
   color: #ffffff;
   border-bottom: 0;
   padding: 7px 15px;
   border-radius: 0;

   .modal-title {
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: -1px;
   }
}

.modal-footer {
   border-radius: 0;
}

.modal-content {
   border: 0 none;
   border-radius: 0;
}

.spinner-border {
   width: 23px;
   height: 23px;
   margin: -5px 10px;
   color: #6f92a7;
}

.ol-full-screen,
.ol-zoom,
.ol-zoom-extent,
.ol-rotate {
   button {
      font-size: 14px;
      font-family: sans-serif !important;
      font-weight: 500;
      line-height: 20px;
      width: 20px;
      height: 20px;
   }
}

.ol-full-screen {
   top: 15px;
   left: 15px;
   right: unset;

   button {
      font-family: sans-serif !important;
   }
}

.ol-zoom {
   top: 50px;
   left: 15px;
   right: unset;
}

.ol-zoom-extent {
   top: 110px;
   left: 15px;
   right: unset;
}

.ol-rotate {
   top: 150px;
   left: 15px;
   right: unset;
}

.app,
.modal-dialog {
   ::-webkit-scrollbar {
      width: 20px;
   }

   ::-webkit-scrollbar-track {
      background-color: transparent;
   }

   ::-webkit-scrollbar-thumb {
      background-color: #d6dee1;
      border-radius: 20px;
      border: 6px solid transparent;
      background-clip: content-box;
   }

   ::-webkit-scrollbar-thumb:hover {
      background-color: #8aaec3;
   }
}
