.validation-errors {
   bottom: 0;
   right: 0;
   width: 500px;
   position: absolute;
   border-bottom: 0 !important;

   .box-header {
      background-color: #db3636 !important;
      border-bottom: 1px solid #db3636 !important;
   }

   .box-content {
      border-bottom: 0 !important;
   }

   &.box-expanded .expand-button::after {
      transform: rotate(90deg) !important;
   }

   &:not(.box-expanded) {
      width: auto !important;

      .box-header {
         border-bottom: 0 !important;
      }

      .box-content {
         display: none !important;
      }
   }

   .rules {
      max-height: 375px;
      overflow-y: auto;
   }
}
