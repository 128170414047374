.sidebar-hidden .left-content {
   display: none !important;
}

.map-container {
   display: flex;   

   .map {
      width: 100%;
      height: calc(100vh - 32px);
   }
}
