.plan-info {     
   .box-content {
      span {
         display: block;
      }

      .name {
         margin-bottom: 5px;
      }
   }
}