.spinner {
   position: absolute;
   width: 70px;
   text-align: center;
   top: calc(50% - 35px);
   left: calc(50% - 35px);
 }
 
 .spinner > div {
   width: 18px;
   height: 18px;
   background-color: #8aaec3;
   border-radius: 100%;
   display: inline-block;   
   margin-right: 5px;
   -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
   animation: sk-bouncedelay 1.4s infinite ease-in-out both;

   &:last-child {
      margin-right: 0 none;
   }
 }
 
 .spinner .bounce1 {
   -webkit-animation-delay: -0.32s;
   animation-delay: -0.32s;
 }
 
 .spinner .bounce2 {
   -webkit-animation-delay: -0.16s;
   animation-delay: -0.16s;
 }
 
 @-webkit-keyframes sk-bouncedelay {
   0%, 80%, 100% { -webkit-transform: scale(0) }
   40% { -webkit-transform: scale(1.0) }
 }
 
 @keyframes sk-bouncedelay {
   0%, 80%, 100% { 
     -webkit-transform: scale(0);
     transform: scale(0);
   } 40% { 
     -webkit-transform: scale(1.0);
     transform: scale(1.0);
   }
 }