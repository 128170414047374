button.close {
   background: url('../assets/gfx/icon-close.svg');
   width: 12px;
   height: 12px;
   border: 0 none;
   background-size: 12px;

   > * {
      display: none;
   }
}

.expand-button {
   display: flex;
   align-items: center;
   text-decoration: underline;
   color: #333333;
   font-weight: 400;
   line-height: 16px;
   user-select: none;

   &:hover {
      color: #333333;
      text-decoration: none;
   }

   &::after {
      content: "";
      background: url("../assets/gfx/icon-arrow.svg") no-repeat center;
      background-size: 11px;
      width: 11px;
      height: 11px;
      transition: all 0.25s ease;
      margin-left: 10px;
   }
}

.box-expanded {
   > .expand-button {

      &::after {
         transform: rotate(90deg);
      }
   }
}