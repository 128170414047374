.splash-screen > div {
   position: absolute;
   width: 369px;
   height: 200px;
   top: calc(50% - 90px);
   background: white;
   padding: 25px;
   box-shadow: 0 3px 10px rgb(0 0 0 / 7%);
   display: flex;
   left: calc(50% - 184px);

   img {
      margin-right: 25px;
   }

   .header {
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {
         font-weight: 100;
      }

      span:nth-child(1) {
         margin-bottom: 0px;
         font-size: 30px;
         line-height: 30px;
         color: #8aaec3;
         font-weight: 500;
      }

      span:nth-child(2) {
         font-size: 20px;
      }
   }

   .spinner {
      position: absolute;
      bottom: -50px;
      top: unset;
   }
}
