.map-info {
   width: 100%;
   position: relative !important;

   .scaleBar {
      margin: 35px 4px 5px 4px;
   }

   .north-arrow {
      position: absolute;
      width: 40px;
      right: 15px;
      bottom: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
         color: #333333;
         font-size: 28px;
         line-height: 28px;
         margin-bottom: 5px;
         font-weight: 500;
      }

      img {
         width: 40px;
      }
   }
}
