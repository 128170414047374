.scaleBar {
   display: flex;
   font-size: 13px;

   > span {
      position: relative;
      border-right: 1px solid #333333;
      height: 16px;

      &:last-child {
         border-right: 0;
      }

      &::after {
         content: "";
         background: #333333;
         width: 100%;
         height: 8px;
         display: block;
         position: absolute;
      }

      &:first-child::after {
         content: none;
      }

      &:nth-child(odd)::after {
         top: 0;
      }

      &:nth-child(even)::after {
         bottom: 0;
      }

      span {
         position: absolute;
         right: -15px;
         top: -22px;
         width: 30px;
         overflow: visible;
         white-space: nowrap;
         text-align: center;
      }
   }
}